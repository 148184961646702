import * as React from "react"

import Layout from "../components/Layout"

function ReportsPage({ data }) {
  return (
    <Layout>
    </Layout>
  )
}

export default ReportsPage
